.pushNone {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0)
}

.pushLeft {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%)
}

.pushRight {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%)
}

.pushTop {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%)
}

.pushBottom {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%)
}

.pushNoneY {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
}

.transitionDelay0 {
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s
}

.transition {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s
}

.transitionSlow {
    -webkit-transition: all .8s;
    -moz-transition: all .8s;
    transition: all .8s
}

.transition2x {
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s
}

.transitionDelay1x {
    -webkit-transition-delay: .5s;
    -moz-transition-delay: .5s;
    -ms-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s
}

.transitionDelay2x {
    -webkit-transition-delay: 1s;
    -moz-transition-delay: 1s;
    -ms-transition-delay: 1s;
    -o-transition-delay: 1s;
    transition-delay: 1s
}

.transitionDelay3x {
    -webkit-transition-delay: 1.5s;
    -moz-transition-delay: 1.5s;
    -ms-transition-delay: 1.5s;
    -o-transition-delay: 1.5s;
    transition-delay: 1.5s
}

.fillModeBoth {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both
}

.fillModeBackwards {
    -webkit-animation-fill-mode: backwards;
    -moz-animation-fill-mode: backwards;
    -o-animation-fill-mode: backwards;
    animation-fill-mode: backwards
}

.fillModeForwards {
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.scrollTransition {
    -webkit-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
    -moz-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
    -ms-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
    -o-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
    transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
    -webkit-transition-delay: .3s;
    -moz-transition-delay: .3s;
    -ms-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s
}

@media all and (min-width:1024px) {
    .blindHover {
        overflow: hidden
    }

    .blindHover span {
        position: relative;
        display: inline-block;
        color: #000;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s
    }

    .blindHover span:before {
        content: attr(data-hover);
        position: absolute;
        top: 100%;
        color: #000;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    .blindHover:hover span {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    footer .snWrapper a,
    .sidebar .langWrapper a {
        overflow: hidden;
        line-height: 12px
    }

    footer .snWrapper a span,
    .sidebar .langWrapper a span {
        position: relative;
        display: inline-block;
        color: #000;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s
    }

    footer .snWrapper a span:before,
    .sidebar .langWrapper a span:before {
        content: attr(data-hover);
        position: absolute;
        top: 100%;
        color: #000;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    footer .snWrapper a:hover span,
    .sidebar .langWrapper a:hover span {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    .sidebar .langWrapper a span:before {
        top: auto;
        right: 100%
    }

    .sidebar .langWrapper a:hover span {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%)
    }

    .mainWrapper section.intro .bottomBlock .bigLabel div:first-child,
    .mainWrapper section.intro .bottomBlock .desc div:first-child,
    .mainWrapper section.intro .bottomBlock .awards div:first-child {
        -webkit-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -moz-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -ms-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -o-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -webkit-transition-delay: .3s;
        -moz-transition-delay: .3s;
        -ms-transition-delay: .3s;
        -o-transition-delay: .3s;
        transition-delay: .3s
    }

    .mainWrapper section.info .data .title,
    .mainWrapper section.info .awards .title,
    .mainWrapper section.info .data .infoRow,
    .mainWrapper section.info .awards .infoRow {
        -webkit-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -moz-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -ms-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -o-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -webkit-transition-delay: .3s;
        -moz-transition-delay: .3s;
        -ms-transition-delay: .3s;
        -o-transition-delay: .3s;
        transition-delay: .3s
    }

    .mainWrapper section.hireUs .textBlock div,
    .mainWrapper section.hireUs .textBlock div:nth-child(odd) {
        -webkit-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -moz-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -ms-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -o-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -webkit-transition-delay: .3s;
        -moz-transition-delay: .3s;
        -ms-transition-delay: .3s;
        -o-transition-delay: .3s;
        transition-delay: .3s
    }

    .mainWrapper section.hireUs .formBlock {
        -webkit-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -moz-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -ms-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -o-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -webkit-transition-delay: .3s;
        -moz-transition-delay: .3s;
        -ms-transition-delay: .3s;
        -o-transition-delay: .3s;
        transition-delay: .3s
    }

    .mainWrapper section.hireUs .formBlock .text,
    .mainWrapper section.hireUs .formBlock .formWrapper .form-group .form-control {
        -webkit-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -moz-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -ms-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -o-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -webkit-transition-delay: .3s;
        -moz-transition-delay: .3s;
        -ms-transition-delay: .3s;
        -o-transition-delay: .3s;
        transition-delay: .3s
    }

    .mainWrapper section.service .description div,
    .mainWrapper section.service .list ul li {
        -webkit-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -moz-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -ms-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -o-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -webkit-transition-delay: .3s;
        -moz-transition-delay: .3s;
        -ms-transition-delay: .3s;
        -o-transition-delay: .3s;
        transition-delay: .3s
    }

    .mainWrapper section:not(.active).intro .bottomBlock .bigLabel div:first-child {
        margin-bottom: 2.2222222222222223vh
    }

    .mainWrapper section:not(.active).intro .bottomBlock .desc div:first-child {
        margin-bottom: 3.8271604938271606vh
    }

    .mainWrapper section:not(.active).intro .bottomBlock .awards div:first-child {
        margin-bottom: 1.9753086419753088vh
    }

    .mainWrapper section:not(.active).info .data .title,
    .mainWrapper section:not(.active).info .awards .title {
        margin-bottom: 4.444444444444445vh
    }

    .mainWrapper section:not(.active).info .data .infoRow,
    .mainWrapper section:not(.active).info .awards .infoRow {
        margin-bottom: 5.555555555555555vh
    }

    .mainWrapper section:not(.active).hireUs .textBlock div,
    .mainWrapper section:not(.active).hireUs .textBlock div:nth-child(odd) {
        margin-bottom: 3.7037037037037037vh
    }

    .mainWrapper section:not(.active).hireUs .formBlock {
        -webkit-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -moz-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -ms-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -o-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -webkit-transition-delay: .3s;
        -moz-transition-delay: .3s;
        -ms-transition-delay: .3s;
        -o-transition-delay: .3s;
        transition-delay: .3s;
        min-height: 60vh
    }

    .mainWrapper section:not(.active).hireUs .formBlock .text {
        margin-bottom: 11.481481481481483vh
    }

    .mainWrapper section:not(.active).hireUs .formBlock .formWrapper .form-group .form-control {
        padding: 4.074074074074074vh 0;
        height: 10vh
    }

    .mainWrapper section:not(.active).service .description div {
        margin-bottom: 5.555555555555555vh
    }

    .mainWrapper section:not(.active).service .list ul li {
        margin-bottom: 3.7037037037037037vh
    }

    .mainWrapper section.active+section:not(.active).intro .bottomBlock .bigLabel div:first-child {
        margin-bottom: .7407407407407408vh
    }

    .mainWrapper section.active+section:not(.active).intro .bottomBlock .desc div:first-child {
        margin-bottom: 1.3580246913580247vh
    }

    .mainWrapper section.active+section:not(.active).intro .bottomBlock .awards div:first-child {
        margin-bottom: .4938271604938272vh
    }

    .mainWrapper section.active+section:not(.active).info .data .title,
    .mainWrapper section.active+section:not(.active).info .awards .title {
        margin-bottom: 1.4814814814814816vh
    }

    .mainWrapper section.active+section:not(.active).info .data .infoRow,
    .mainWrapper section.active+section:not(.active).info .awards .infoRow {
        margin-bottom: 1.8518518518518519vh
    }

    .mainWrapper section.active+section:not(.active).hireUs .textBlock div,
    .mainWrapper section.active+section:not(.active).hireUs .textBlock div:nth-child(odd) {
        margin-bottom: 1.234567901234568vh
    }

    .mainWrapper section.active+section:not(.active).hireUs .formBlock {
        min-height: 47.65432098765432vh;
        -webkit-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -moz-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -ms-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -o-transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        transition: all .7s cubic-bezier(0.6, 0, 0.4, 1);
        -webkit-transition-delay: .3s;
        -moz-transition-delay: .3s;
        -ms-transition-delay: .3s;
        -o-transition-delay: .3s;
        transition-delay: .3s
    }

    .mainWrapper section.active+section:not(.active).hireUs .formBlock .text {
        margin-bottom: 3.9506172839506175vh
    }

    .mainWrapper section.active+section:not(.active).hireUs .formBlock .formWrapper .form-group .form-control {
        padding: 1.3580246913580247vh 0;
        height: 4.567901234567901vh
    }

    .mainWrapper section.active+section:not(.active).service .description div {
        margin-bottom: 1.8518518518518519vh
    }

    .mainWrapper section.active+section:not(.active).service .list ul li {
        margin-bottom: 1.234567901234568vh
    }

    .mainWrapper section.intro .bottomBlock .bigLabel div span,
    .mainWrapper section.intro .bottomBlock .desc div span,
    .mainWrapper section.intro .bottomBlock .awards div span {
        position: relative;
        display: inline-block
    }

    footer .snWrapper:before,
    footer .copyright:before,
    .mainWrapper section.intro .topBlock .mainImage:before,
    .mainWrapper section.intro .bottomBlock .bigLabel div span:before,
    .mainWrapper section.intro .bottomBlock .desc div span:before,
    .mainWrapper section.intro .bottomBlock .awards div span:before,
    h1:not(.bigLabel):before,
    .mainWrapper section.service .description div:before,
    .mainWrapper section.contact .infoTextRight:before,
    .mainWrapper section.contact .infoTextLeft:before,
    .mainWrapper section.contact .contactInfo .contactRow .value div:before,
    .mainWrapper section.contact .contactInfo .contactRow .label span:before,
    .servicesTitles a.active:before,
    .mainWrapper section.service .list ul li span:before,
    .mainWrapper section.works .worksList .work .infoWrapper .title span:before,
    .mainWrapper section.works .worksList .work .infoWrapper .counter span:before,
    .mainWrapper section.works .worksList .work .infoWrapper .desc:before,
    .mainWrapper section.works .worksList .work .infoWrapper .action:before,
    footer .snWrapper:after,
    footer .copyright:after,
    .mainWrapper section.intro .topBlock .mainImage:after,
    .mainWrapper section.intro .bottomBlock .bigLabel div span:after,
    .mainWrapper section.intro .bottomBlock .desc div span:after,
    .mainWrapper section.intro .bottomBlock .awards div span:after,
    h1:not(.bigLabel):after,
    .mainWrapper section.service .description div:after,
    .mainWrapper section.contact .infoTextRight:after,
    .mainWrapper section.contact .infoTextLeft:after,
    .mainWrapper section.contact .contactInfo .contactRow .value div:after,
    .mainWrapper section.contact .contactInfo .contactRow .label span:after,
    .servicesTitles a.active:after,
    .mainWrapper section.service .list ul li span:after,
    .mainWrapper section.works .worksList .work .infoWrapper .title span:after,
    .mainWrapper section.works .worksList .work .infoWrapper .counter span:after,
    .mainWrapper section.works .worksList .work .infoWrapper .desc:after,
    .mainWrapper section.works .worksList .work .infoWrapper .action:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
        z-index: 1
    }

    footer .snWrapper:before,
    footer .copyright:before,
    .mainWrapper section.intro .topBlock .mainImage:before,
    .mainWrapper section.intro .bottomBlock .bigLabel div span:before,
    .mainWrapper section.intro .bottomBlock .desc div span:before,
    .mainWrapper section.intro .bottomBlock .awards div span:before,
    h1:not(.bigLabel):before,
    .mainWrapper section.service .description div:before,
    .mainWrapper section.contact .infoTextRight:before,
    .mainWrapper section.contact .infoTextLeft:before,
    .mainWrapper section.contact .contactInfo .contactRow .value div:before,
    .mainWrapper section.contact .contactInfo .contactRow .label span:before,
    .servicesTitles a.active:before,
    .mainWrapper section.service .list ul li span:before,
    .mainWrapper section.works .worksList .work .infoWrapper .title span:before,
    .mainWrapper section.works .worksList .work .infoWrapper .counter span:before,
    .mainWrapper section.works .worksList .work .infoWrapper .desc:before,
    .mainWrapper section.works .worksList .work .infoWrapper .action:before {
        background: #fff
    }

    footer .snWrapper:after,
    footer .copyright:after,
    .mainWrapper section.intro .topBlock .mainImage:after,
    .mainWrapper section.intro .bottomBlock .bigLabel div span:after,
    .mainWrapper section.intro .bottomBlock .desc div span:after,
    .mainWrapper section.intro .bottomBlock .awards div span:after,
    h1:not(.bigLabel):after,
    .mainWrapper section.service .description div:after,
    .mainWrapper section.contact .infoTextRight:after,
    .mainWrapper section.contact .infoTextLeft:after,
    .mainWrapper section.contact .contactInfo .contactRow .value div:after,
    .mainWrapper section.contact .contactInfo .contactRow .label span:after,
    .servicesTitles a.active:after,
    .mainWrapper section.service .list ul li span:after,
    .mainWrapper section.works .worksList .work .infoWrapper .title span:after,
    .mainWrapper section.works .worksList .work .infoWrapper .counter span:after,
    .mainWrapper section.works .worksList .work .infoWrapper .desc:after,
    .mainWrapper section.works .worksList .work .infoWrapper .action:after {
        background: #000;
        width: 0
    }

    .mainWrapper section.service .description div,
    .mainWrapper section.contact .contactInfo .contactRow .value div,
    .mainWrapper section.contact .contactInfo .contactRow .label span,
    .mainWrapper section.service .list ul li span,
    .mainWrapper section.works .worksList .work .infoWrapper .title span,
    .mainWrapper section.works .worksList .work .infoWrapper .counter span,
    .mainWrapper section.works .worksList .work .infoWrapper .desc,
    .mainWrapper section.works .worksList .work .infoWrapper .action {
        position: relative
    }

    .mainWrapper section.service .list ul li:before {
        opacity: 0
    }

    .mainWrapper section.contact .contactInfo .contactRow .label span {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.234567901234568vh
    }

    .mainWrapper section.contact .formWrapper .form-group {
        width: 0
    }

    .mainWrapper section.contact .formWrapper .form-group input,
    .mainWrapper section.contact .formWrapper .form-group textarea {
        opacity: 0
    }

    .mainWrapper section.intro .topBlock .mainImage {
        position: relative;
        width: 100vw
    }

    .mainWrapper section.intro .topBlock .mainImage img {
        z-index: 2;
        position: relative
    }

    header .hireBtn:after,
    .mainWrapper section.contact .submitWrapper .btn:after,
    .mainWrapper section.works .worksList .work .infoWrapper .btns .btn:after {
        color: rgba(255, 255, 255, 0);
        -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
        -webkit-transition: all .5s 0s, color .5s .5s;
        -moz-transition: all .5s 0s, color .5s .5s;
        -ms-transition: all .5s 0s, color .5s .5s;
        -o-transition: all .5s 0s, color .5s .5s;
        transition: all .5s 0s, color .5s .5s
    }

    .btn div {
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s
    }

    .btn:after {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s
    }

    .btn:hover div {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }

    .btn:hover:after {
        -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%) !important;
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%) !important
    }

    header .logoWrapper {
        display: inline-block;
        overflow: hidden
    }

    header .logoWrapper:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: #fff;
        position: absolute;
        left: 0;
        top: 0
    }

    .sidebar {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%)
    }

    .mainWrapper section.intro .topBlock:before {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    .pager.bottom .bullets.s0:before {
        left: 0;
        width: 10px
    }

    .pager.bottom .bullets.s0.s1:before {
        width: calc(5.034722vw - -5px)
    }

    .pager.bottom .bullets.s1:not(.s0):before {
        left: calc(5.034722vw - 5px);
        width: 10px
    }

    .pager.bottom .bullets.s1.s2:before {
        left: calc(5.034722vw - 5px);
        width: calc(5.034722vw - -4px)
    }

    .pager.bottom .bullets.s2:not(.s0):not(.s1):before {
        left: calc(10.069444vw - 11px);
        width: 10px
    }

    .pager.bottom .bullets.s0.s2:before {
        width: 100%
    }

    .pager.bottom .bullets,
    .pager.left .bullets {
        position: relative
    }

    .pager.bottom .bullets:before,
    .pager.left .bullets:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 11px;
        background: #000;
        top: 0;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s
    }

    .pager.bottom .bullets.s0:before,
    .pager.left .bullets.s0:before {
        width: 0
    }

    .pager.bottom .bullets .bullet:before,
    .pager.left .bullets .bullet:before {
        width: 0;
        border-left-width: 0;
        border-right-width: 0
    }

    .pager.left .bullets.s0:before {
        width: 11px;
        top: 0
    }

    .pager.left .bullets.s0.s1:before {
        height: calc(14.567901vh - -22px)
    }

    .pager.left .bullets.s1:not(.s0):before {
        top: calc(14.567901vh - -11px);
        height: 11px
    }

    .pager.left .bullets.s1.s2:before {
        top: calc(14.567901vh - -11px);
        height: calc(14.567901vh - -22px)
    }

    .pager.left .bullets.s2:not(.s0):not(.s1):before {
        top: calc(29.135802vh - -22px);
        height: 11px
    }

    .pager.left .bullets.s0.s2:before {
        width: 100%
    }

    .pager.left .bullets.s0:before {
        width: 0
    }

    .pager .labelWrapper .label {
        background: 0;
        position: relative;
        color: rgba(255, 255, 255, 0)
    }

    .pager .labelWrapper .label:before {
        content: '';
        display: block;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background: #000;
        z-index: -1
    }

    .mainWrapper section.contact:before {
        height: 0
    }

    .mainWrapper section.service.web .rect,
    .mainWrapper section.service.mobile .rect,
    .mainWrapper section.service.rules .rect {
        width: 0
    }

    .mainWrapper section.service .rect {
        overflow: hidden;
        padding-left: 0;
        padding-right: 0
    }

    .mainWrapper section.service .rect .resolution {
        opacity: 0
    }

    .servicesTitles {
        overflow: hidden
    }

    .servicesTitles a {
        -webkit-transition: transform .7s cubic-bezier(0.3, 0, 0.7, 1) .2s;
        -moz-transition: transform .7s cubic-bezier(0.3, 0, 0.7, 1) .2s;
        -ms-transition: transform .7s cubic-bezier(0.3, 0, 0.7, 1) .2s;
        -o-transition: transform .7s cubic-bezier(0.3, 0, 0.7, 1) .2s;
        transition: transform .7s cubic-bezier(0.3, 0, 0.7, 1) .2s;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    .servicesTitles a.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }

    .servicesTitles a.active+a,
    .servicesTitles a.active+a+a {
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%)
    }

    .mainWrapper section.works .worksList .work .infoWrapper .btns .outerLink {
        opacity: 0
    }

    .mainWrapper section.works .worksList .work .imgWrapper .image {
        width: 0;
        position: relative
    }

    .mainWrapper section.works .worksList .work .imgWrapper .image:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000
    }

    body.loaded .pager .labelWrapper .label {
        color: #fff;
        -webkit-transition: all .5s .5s;
        -moz-transition: all .5s .5s;
        -ms-transition: all .5s .5s;
        -o-transition: all .5s .5s;
        transition: all .5s .5s
    }

    body.loaded .pager .labelWrapper .label:before {
        width: 100%;
        -webkit-transition: all .5s 0s;
        -moz-transition: all .5s 0s;
        -ms-transition: all .5s 0s;
        -o-transition: all .5s 0s;
        transition: all .5s 0s
    }

    body.loaded .pager .labelWrapper .label.end {
        color: rgba(255, 255, 255, 0)
    }

    body.loaded .pager.bottom .bullets:before,
    body.loaded .pager.left .bullets:before {
        width: 11px
    }

    body.loaded .pager.bottom .bullets .bullet:before,
    body.loaded .pager.left .bullets .bullet:before {
        width: 100%;
        border-left-width: 2px;
        border-right-width: 2px;
        -webkit-transition: all .5s 0s;
        -moz-transition: all .5s 0s;
        -ms-transition: all .5s 0s;
        -o-transition: all .5s 0s;
        transition: all .5s 0s
    }

    body.loaded .mainWrapper section.intro .topBlock:before {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s
    }

    body.loaded .sidebar {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: all .5s .5s, border-left .5s 0s;
        -moz-transition: all .5s .5s, border-left .5s 0s;
        -ms-transition: all .5s .5s, border-left .5s 0s;
        -o-transition: all .5s .5s, border-left .5s 0s;
        transition: all .5s .5s, border-left .5s 0s
    }

    body.loaded footer .snWrapper,
    body.loaded footer .copyright,
    body.loaded .mainWrapper section.intro .topBlock .mainImage,
    body.loaded .mainWrapper section.intro .bottomBlock .bigLabel div span,
    body.loaded .mainWrapper section.intro .bottomBlock .desc div span,
    body.loaded .mainWrapper section.intro .bottomBlock .awards div span,
    body.loaded h1:not(.bigLabel),
    body.loaded .mainWrapper section.service .description div,
    body.loaded .mainWrapper section.contact .infoTextRight,
    body.loaded .mainWrapper section.contact .infoTextLeft,
    body.loaded .mainWrapper section.contact .contactInfo .contactRow .value div,
    body.loaded .mainWrapper section.contact .contactInfo .contactRow .label span,
    body.loaded .servicesTitles a.active,
    body.loaded .mainWrapper section.service .list ul li span,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .title span,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .counter span,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .desc,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .action {
        overflow: hidden
    }

    body.loaded footer .snWrapper:after,
    body.loaded footer .copyright:after,
    body.loaded .mainWrapper section.intro .topBlock .mainImage:after,
    body.loaded .mainWrapper section.intro .bottomBlock .bigLabel div span:after,
    body.loaded .mainWrapper section.intro .bottomBlock .desc div span:after,
    body.loaded .mainWrapper section.intro .bottomBlock .awards div span:after,
    body.loaded h1:not(.bigLabel):after,
    body.loaded .mainWrapper section.service .description div:after,
    body.loaded .mainWrapper section.contact .infoTextRight:after,
    body.loaded .mainWrapper section.contact .infoTextLeft:after,
    body.loaded .mainWrapper section.contact .contactInfo .contactRow .value div:after,
    body.loaded .mainWrapper section.contact .contactInfo .contactRow .label span:after,
    body.loaded .servicesTitles a.active:after,
    body.loaded .mainWrapper section.service .list ul li span:after,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .title span:after,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .counter span:after,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .desc:after,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .action:after {
        width: 100%
    }

    body.loaded footer .snWrapper:before,
    body.loaded footer .copyright:before,
    body.loaded .mainWrapper section.intro .topBlock .mainImage:before,
    body.loaded .mainWrapper section.intro .bottomBlock .bigLabel div span:before,
    body.loaded .mainWrapper section.intro .bottomBlock .desc div span:before,
    body.loaded .mainWrapper section.intro .bottomBlock .awards div span:before,
    body.loaded h1:not(.bigLabel):before,
    body.loaded .mainWrapper section.service .description div:before,
    body.loaded .mainWrapper section.contact .infoTextRight:before,
    body.loaded .mainWrapper section.contact .infoTextLeft:before,
    body.loaded .mainWrapper section.contact .contactInfo .contactRow .value div:before,
    body.loaded .mainWrapper section.contact .contactInfo .contactRow .label span:before,
    body.loaded .servicesTitles a.active:before,
    body.loaded .mainWrapper section.service .list ul li span:before,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .title span:before,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .counter span:before,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .desc:before,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .action:before,
    body.loaded footer .snWrapper:after,
    body.loaded footer .copyright:after,
    body.loaded .mainWrapper section.intro .topBlock .mainImage:after,
    body.loaded .mainWrapper section.intro .bottomBlock .bigLabel div span:after,
    body.loaded .mainWrapper section.intro .bottomBlock .desc div span:after,
    body.loaded .mainWrapper section.intro .bottomBlock .awards div span:after,
    body.loaded h1:not(.bigLabel):after,
    body.loaded .mainWrapper section.service .description div:after,
    body.loaded .mainWrapper section.contact .infoTextRight:after,
    body.loaded .mainWrapper section.contact .infoTextLeft:after,
    body.loaded .mainWrapper section.contact .contactInfo .contactRow .value div:after,
    body.loaded .mainWrapper section.contact .contactInfo .contactRow .label span:after,
    body.loaded .servicesTitles a.active:after,
    body.loaded .mainWrapper section.service .list ul li span:after,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .title span:after,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .counter span:after,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .desc:after,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .action:after {
        -webkit-transform: translateX(102%);
        -moz-transform: translateX(102%);
        -ms-transform: translateX(102%);
        -o-transform: translateX(102%);
        transform: translateX(102%);
        -webkit-transition: width .5s 0s, transform .5s .5s;
        -moz-transition: width .5s 0s, transform .5s .5s;
        -ms-transition: width .5s 0s, transform .5s .5s;
        -o-transition: width .5s 0s, transform .5s .5s;
        transition: width .5s 0s, transform .5s .5s
    }

    body.loaded .mainWrapper section.intro .topBlock .mainImage {
        width: 93.75vw;
        -webkit-transition: width .5s .5s;
        -moz-transition: width .5s .5s;
        -ms-transition: width .5s .5s;
        -o-transition: width .5s .5s;
        transition: width .5s .5s
    }

    body.loaded header .logoWrapper:after {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: all .5s .5s;
        -moz-transition: all .5s .5s;
        -ms-transition: all .5s .5s;
        -o-transition: all .5s .5s;
        transition: all .5s .5s
    }

    body.loaded header .hireBtn:after,
    body.loaded .mainWrapper section.contact .submitWrapper .btn:after,
    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .btns .btn:after {
        color: #fff;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
    }

    body.loaded .mainWrapper section.contact:before {
        height: 100vh;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -ms-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s
    }

    body.loaded .mainWrapper section.contact .formWrapper .form-group {
        width: 100%;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s
    }

    body.loaded .mainWrapper section.contact .formWrapper .form-group input,
    body.loaded .mainWrapper section.contact .formWrapper .form-group textarea {
        opacity: 1;
        -webkit-transition: opacity .5s .5s;
        -moz-transition: opacity .5s .5s;
        -ms-transition: opacity .5s .5s;
        -o-transition: opacity .5s .5s;
        transition: opacity .5s .5s
    }

    body.loaded .mainWrapper section.service.web .rect {
        width: 12.5vw
    }

    body.loaded .mainWrapper section.service.mobile .rect {
        width: 4.861111111111111vw
    }

    body.loaded .mainWrapper section.service.rules .rect {
        width: 12.5vw
    }

    body.loaded .mainWrapper section.service .rect {
        padding-left: 1.3888888888888888vw;
        padding-right: 1.3888888888888888vw;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s
    }

    body.loaded .mainWrapper section.service .rect .resolution {
        opacity: 1;
        -webkit-transition: opacity .5s .5s;
        -moz-transition: opacity .5s .5s;
        -ms-transition: opacity .5s .5s;
        -o-transition: opacity .5s .5s;
        transition: opacity .5s .5s
    }

    body.loaded .mainWrapper section.service .list ul li:before {
        opacity: 1;
        -webkit-transition: opacity .5s .5s;
        -moz-transition: opacity .5s .5s;
        -ms-transition: opacity .5s .5s;
        -o-transition: opacity .5s .5s;
        transition: opacity .5s .5s
    }

    body.loaded .mainWrapper section.works .worksList .work .infoWrapper .btns .outerLink {
        opacity: 1;
        -webkit-transition: opacity .5s .5s;
        -moz-transition: opacity .5s .5s;
        -ms-transition: opacity .5s .5s;
        -o-transition: opacity .5s .5s;
        transition: opacity .5s .5s
    }

    body.loaded .mainWrapper section.works .worksList .work .imgWrapper:after {
        -webkit-transition: width .5s, opacity .3s;
        -moz-transition: width .5s, opacity .3s;
        -ms-transition: width .5s, opacity .3s;
        -o-transition: width .5s, opacity .3s;
        transition: width .5s, opacity .3s;
        width: 100%
    }

    body.loaded .mainWrapper section.works .worksList .work .imgWrapper .image {
        width: 100%;
        -webkit-transition: width .5s, transform 1s;
        -moz-transition: width .5s, transform 1s;
        -ms-transition: width .5s, transform 1s;
        -o-transition: width .5s, transform 1s;
        transition: width .5s, transform 1s
    }

    body.loaded .mainWrapper section.works .worksList .work .imgWrapper .image:after {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: transform .5s .5s;
        -moz-transition: transform .5s .5s;
        -ms-transition: transform .5s .5s;
        -o-transition: transform .5s .5s;
        transition: transform .5s .5s
    }

    .mainWrapper section.works .worksList .work .infoWrapper {
        position: relative
    }

    .mainWrapper section.works .worksList .work .imgWrapper {
        position: relative
    }

    .mainWrapper section.works .worksList .work .imgWrapper:after {
        content: '';
        display: block;
        width: 0;
        height: 100%;
        background: #000;
        opacity: 1;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
        position: absolute;
        left: 0;
        top: 0
    }

    .mainWrapper section.works .worksList .work.current .imgWrapper:after {
        opacity: 0
    }
}

@-webkit-keyframes preloader {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes preloader {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.preloader img {
    -webkit-animation: preloader 4s ease;
    animation: preloader 4s ease
}

.scrollWrapper[data-scrollbar=true] {
    height: 100%
}