@font-face {
    font-family: GothamProRegular;
    src: url("/fonts/GothamProRegular.eot");
    src: url("/fonts/GothamProRegular.eot?#iefix") format("embedded-opentype"), url("/fonts/GothamProRegular.woff") format("woff"), url("/fonts/GothamProRegular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
  }
  
  @font-face {
    font-family: GothamProMedium;
    src: url("/fonts/GothamProMedium.eot");
    src: url("/fonts/GothamProMedium.eot?#iefix") format("embedded-opentype"), url("/fonts/GothamProMedium.woff") format("woff"), url("/fonts/GothamProMedium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
  }
  
  @font-face {
    font-family: GothamProBold;
    src: url("/fonts/GothamProBold.eot");
    src: url("/fonts/GothamProBold.eot?#iefix") format("embedded-opentype"), url("/fonts/GothamProBold.woff") format("woff"), url("/fonts/GothamProBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
  }
  
  @font-face {
    font-family: GothamProBlack;
    src: url("/fonts//GothamProBlack.eot");
    src: url("/fonts//GothamProBlack.eot?#iefix") format("embedded-opentype"), url("/fonts//GothamProBlack.woff") format("woff"), url("/fonts//GothamProBlack.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
  }
  
  html,
  body {
    font-family: GothamProMedium;
    margin: 0;
    padding: 0;
    color: #000;
    background: #fff;
    font-size: 11px;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    cursor: url("/img/cursors/cursor.png") 6 6, auto;
    cursor: url("/img/cursors/cursor.svg") 6 6, auto;
    cursor: -webkit-image-set(url("/img/cursors/cursor.png") 1x, url("/img/cursors/cursor@2x.png") 2x) 6 6, auto
  }
  
  .cursorPointer {
    cursor: url("/img/cursors/cursor_active.png") 10 10, pointer;
    cursor: url("/img/cursors/cursor_active.svg") 10 10, pointer;
    cursor: -webkit-image-set(url("/img/cursors/cursor_active.png") 1x, url("/img/cursors/cursor_active@2x.png") 2x) 10 10, pointer
  }
  
  a {
    color: #000;
    text-decoration: none;
    cursor: url("/img/cursors/cursor_active.png") 10 10, pointer;
    cursor: url("/img/cursors/cursor_active.svg") 10 10, pointer;
    cursor: -webkit-image-set(url("/img/cursors/cursor_active.png") 1x, url("/img/cursors/cursor_active@2x.png") 2x) 10 10, pointer
  }
  
  /* ::selection {
    background: #000;
    color: #fff
  } */
  
  /* ::-moz-selection {
    background: #000;
    color: #fff
  } */
  
  .clearfix {
    clear: both
  }
  
  .left {
    float: left !important
  }
  
  .right {
    float: right !important
  }
  
  .center {
    text-align: center
  }
  
  .fixed {
    position: fixed
  }
  
  .bgCover {
    background-size: cover;
    background-position: center center
  }
  
  .pseudoOverlay {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
  }
  
  .absoluteMargin {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto
  }
  
  .textOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }
  
  .transitionDefault {
    -webkit-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    -moz-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    transition: all .5s cubic-bezier(0.6, 0, 0.5, 1)
  }
  
  .transitionSlow {
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s
  }
  
  .borderBox {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important
  }
  
  .contentBox {
    -webkit-box-sizing: content-box !important;
    -moz-box-sizing: content-box !important;
    box-sizing: content-box !important
  }
  
  img {
    max-width: 100%
  }
  
  nav,
  div,
  span,
  header,
  footer,
  section,
  input,
  select,
  textarea,
  button,
  a,
  ul,
  li,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important
  }
  
  input,
  textarea,
  button,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background: 0;
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    padding: 0;
    margin: 0
  }
  
  button {
    cursor: url("/img/cursors/cursor_active.png") 10 10, pointer;
    cursor: url("/img/cursors/cursor_active.svg") 10 10, pointer;
    cursor: -webkit-image-set(url("/img/cursors/cursor_active.png") 1x, url("/img/cursors/cursor_active@2x.png") 2x) 10 10, pointer
  }
  
  .btn {
    display: inline-block;
    text-decoration: none;
    background: 0;
    text-align: left;
    position: relative;
    height: 4.938271604938272vh;
    overflow: hidden
  }
  
  .btn div {
    display: inline-block;
    font-family: GothamProBlack;
    font-size: 3.9506172839506175vh;
    line-height: 4.938271604938272vh;
    color: #000;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-transform: translateX(-101%);
    -moz-transform: translateX(-101%);
    -ms-transform: translateX(-101%);
    -o-transform: translateX(-101%);
    transform: translateX(-101%)
  }
  
  .btn.smHeight div {
    font-size: 2.9629629629629632vh
  }
  
  .btn:after {
    content: attr(data-text);
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-family: GothamProMedium;
    font-size: 1.4814814814814816vh;
    letter-spacing: .06944444444444445vw;
    line-height: 4.938271604938272vh;
    color: #fff;
    text-transform: uppercase;
    background: #000;
    padding: 0 1.3888888888888888vw;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important
  }
  
  body:before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: calc(93.75%);
    height: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    -moz-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    background: #fff;
    z-index: 9
  }
  
  body header .hireBtn {
    -webkit-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    -moz-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    transition: all .5s cubic-bezier(0.6, 0, 0.5, 1)
  }
  
  body footer>div {
    -webkit-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    -moz-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    transition: all .5s cubic-bezier(0.6, 0, 0.5, 1)
  }
  
  body.navHovered:before {
    opacity: 1;
    visibility: visible
  }
  
  body.navHovered footer>div {
    opacity: 0;
    visibility: hidden
  }
  
  body.navHovered .hireBtn {
    opacity: 0;
    visibility: hidden
  }
  
  body.navHovered .sidebar {
    border-left-color: rgba(0, 0, 0, 0)
  }
  
  .sidebar {
    position: fixed;
    width: 6.25vw;
    z-index: 11;
    height: 100%;
    right: 0;
    top: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    font-family: GothamProMedium;
    font-size: 12px;
    line-height: 13px;
    padding-top: 12.345679012345679vh
  }
  
  .sidebar nav ul {
    padding: 0;
    margin: auto
  }
  
  .sidebar nav ul li {
    display: block;
    list-style: none;
    padding: 0
  }
  
  .sidebar nav ul li a {
    display: block;
    margin: 0 auto;
    padding: 15px 13px;
    position: relative;
    color: rgba(0, 0, 0, 0.3)
  }
  
  .sidebar nav ul li a div {
    margin: auto;
    writing-mode: vertical-lr;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    -moz-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    transition: all .5s cubic-bezier(0.6, 0, 0.5, 1)
  }
  
  .sidebar nav ul li a:after {
    content: attr(data-text);
    position: absolute;
    right: -8px;
    top: 0;
    color: #000;
    font-family: GothamProBlack;
    font-size: 96px;
    line-height: 66px;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    -moz-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    transition: all .5s cubic-bezier(0.6, 0, 0.5, 1)
  }
  
  .sidebar nav ul li a:hover:after {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0)
  }
  
  .sidebar nav ul li a:hover div {
    opacity: 0;
    -webkit-transform: translateX(3.125vw) rotate(180deg);
    -moz-transform: translateX(3.125vw) rotate(180deg);
    -ms-transform: translateX(3.125vw) rotate(180deg);
    -o-transform: translateX(3.125vw) rotate(180deg);
    transform: translateX(3.125vw) rotate(180deg)
  }
  
  .sidebar nav ul li:first-child a {
    padding-top: 0
  }
  
  .sidebar nav ul li:last-child a {
    padding-bottom: 0
  }
  
  .sidebar nav ul li.active a {
    color: #000
  }
  
  .sidebar .langWrapper,
  .sidebar .fullscreenTrigger {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center
  }
  
  .sidebar .langWrapper .close,
  .sidebar .fullscreenTrigger .close {
    display: none
  }
  
  .sidebar .langWrapper {
    bottom: 12.345679012345679vh
  }
  
  .sidebar .langWrapper a {
    display: block;
    margin: 0 auto;
    writing-mode: vertical-lr;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  
  .sidebar .fullscreenTrigger {
    bottom: 24.938271604938272vh;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s
  }
  
  .sidebar .fullscreenTrigger:hover {
    opacity: .3
  }
  
  header {
    opacity: 1;
    transition: all .5s;
  }
  
  header .hireBtn,
  header .logoWrapper {
    position: fixed;
    z-index: 10;
    top: 12.345679012345679vh
  }
  
  header.static .hireBtn,
  header.static .logoWrapper {
    position: absolute
  }
  
  header .hireBtn {
    right: 12.5vw;
    width: 12.5vw
  }
  
  header .logoWrapper {
    left: 6.25vw
  }
  
  header .logoWrapper img {
    height: 3.7037037037037037vh
  }
  
  header a {
    -webkit-transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1);
    -moz-transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1);
    -ms-transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1);
    -o-transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1);
    transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1)
  }
  
  header.hidden a {
    opacity: 0
  }
  
  footer>div {
    position: fixed;
    z-index: 10;
    bottom: 12.345679012345679vh
  }
  
  footer.static {
    position: relative;
    padding-bottom: 12.345679012345679vh
  }
  
  footer.static>div {
    position: absolute;
    bottom: auto;
    top: 0
  }
  
  footer .snWrapper {
    display: inline-block;
    left: 6.25vw
  }
  
  footer .snWrapper.hideOnDesktop {
    display: none
  }
  
  footer .snWrapper div,
  footer .snWrapper a {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    line-height: 8px
  }
  
  footer .snWrapper .label {
    width: 6.25vw
  }
  
  footer .snWrapper a {
    margin-right: 1.3888888888888888vw
  }
  
  footer .snWrapper a:last-child {
    margin-right: 0
  }
  
  footer .copyright {
    left: 75vw;
    font-size: 10px;
    line-height: 12px
  }
  
  .formWrapper .form-group {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    -webkit-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    -moz-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    transition: all .5s cubic-bezier(0.6, 0, 0.5, 1)
  }
  
  .formWrapper .form-group:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.1)
  }
  
  .formWrapper .form-group .form-control {
    font-size: 1.3580246913580247vh;
    line-height: 1.8518518518518519vh;
    padding: 2.7160493827160495vh 0;
    height: 7.283950617283951vh;
    color: #000;
    text-transform: uppercase
  }
  
  .formWrapper .form-group textarea {
    resize: none
  }
  
  .formWrapper .form-group ::-webkit-input-placeholder {
    color: #000
  }
  
  .formWrapper .form-group :-moz-placeholder {
    color: #000
  }
  
  .formWrapper .form-group ::-moz-placeholder {
    color: #000
  }
  
  .formWrapper .form-group ::-webkit-input-placeholder {
    text-transform: uppercase
  }
  
  .formWrapper .form-group :-moz-placeholder {
    text-transform: uppercase
  }
  
  .formWrapper .form-group ::-moz-placeholder {
    text-transform: uppercase
  }
  
  .formWrapper .form-group .help-block {
    bottom: 0;
    left: 0;
    font-size: 1.234567901234568vh;
    line-height: .9876543209876544vh;
    margin: 0;
    height: 0;
    opacity: 0;
    color: #f13255;
    -webkit-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    -moz-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    position: absolute;
    top: 4.938271604938272vh
  }
  
  .formWrapper .form-group.has-error {
    border-bottom-color: #f13255;
    padding-bottom: 2.469135802469136vh
  }
  
  .formWrapper .form-group.has-error .help-block {
    opacity: 1;
    height: .9876543209876544vh;
    top: 6.296296296296297vh
  }
  
  h1:not(.bigLabel) {
    position: fixed;
    top: 12.3vh;
    left: 21.3vw;
    font-family: GothamProBold;
    font-size: 4.938271604938272vh;
    line-height: 4.691358024691358vh;
    margin: 0;
    height: 4.197530864197531vh;
    display: inline-block
  }
  
  h1:not(.bigLabel) .counter {
    font-size: 1.3580246913580247vh;
    line-height: .9876543209876544vh
  }
  
  .mainWrapper {
    width: 93.75vw;
    height: 100%
  }
  
  .mainWrapper.static {
    height: auto
  }
  
  .mainWrapper.fullpage section {
    opacity: 0;
    -webkit-transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1);
    -moz-transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1);
    -ms-transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1);
    -o-transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1);
    transition: opacity .5s cubic-bezier(0.3, 0.1, 1, 1)
  }
  
  .mainWrapper.fullpage section.active {
    opacity: 1
  }
  
  .mainWrapper section.intro {
    height: 100%
  }
  
  .mainWrapper section.intro .topBlock {
    height: 50%;
    padding-top: 33.33333333333333vh;
    position: relative
  }
  
  .mainWrapper section.intro .topBlock:before {
    content: '';
    display: block;
    width: 1px;
    height: 17.283950617283953vh;
    background: #000;
    position: absolute;
    top: 0;
    left: 39.93055555555556vw
  }
  
  .mainWrapper section.intro .topBlock .mainImage {
    width: 100%;
    height: 16.666666666666664vh;
    padding-left: 6.25vw;
    background-size: cover;
    background-position: center center
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-1 {
    background-image: url("/img/mainBg/1.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-2 {
    background-image: url("/img/mainBg/2.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-3 {
    background-image: url("/img/mainBg/3.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-4 {
    background-image: url("/img/mainBg/4.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-5 {
    background-image: url("/img/mainBg/5.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-6 {
    background-image: url("/img/mainBg/6.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-7 {
    background-image: url("/img/mainBg/7.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-8 {
    background-image: url("/img/mainBg/8.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-9 {
    background-image: url("/img/mainBg/9.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-10 {
    background-image: url("/img/mainBg/10.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-11 {
    background-image: url("/img/mainBg/11.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-12 {
    background-image: url("/img/mainBg/12.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-13 {
    background-image: url("/img/mainBg/13.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-14 {
    background-image: url("/img/mainBg/14.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-15 {
    background-image: url("/img/mainBg/15.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-16 {
    background-image: url("/img/mainBg/16.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-17 {
    background-image: url("/img/mainBg/17.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-18 {
    background-image: url("/img/mainBg/18.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-19 {
    background-image: url("/img/mainBg/19.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage.state-20 {
    background-image: url("/img/mainBg/20.jpg")
  }
  
  .mainWrapper section.intro .topBlock .mainImage img {
    height: calc(100% - -2px);
    position: relative;
    top: -1px;
    display: block
  }
  
  .mainWrapper section.intro .bottomBlock {
    height: 50%;
    padding-top: 16.666666666666664vh;
    padding-left: 6.25vw;
    font-size: 0
  }
  
  .mainWrapper section.intro .bottomBlock .hireBtn {
    display: none
  }
  
  .mainWrapper section.intro .bottomBlock>div,
  .mainWrapper section.intro .bottomBlock h1 {
    display: inline-block;
    vertical-align: middle
  }
  
  .mainWrapper section.intro .bottomBlock .bigLabel {
    margin: 0;
    padding: 0;
    font-family: GothamProBold;
    font-size: 4.938271604938272vh;
    line-height: 4.938271604938272vh;
    width: 33.68055555555556vw
  }
  
  .mainWrapper section.intro .bottomBlock .bigLabel div {
    height: 3.4567901234567904vh;
    position: relative;
    top: -0.7407407407407408vh
  }
  
  .mainWrapper section.intro .bottomBlock .bigLabel div:first-child {
    margin-bottom: 1.4814814814814816vh
  }
  
  .mainWrapper section.intro .bottomBlock .desc {
    font-size: 1.3580246913580247vh;
    line-height: 1.8518518518518519vh;
    width: 20.13888888888889vw
  }
  
  .mainWrapper section.intro .bottomBlock .desc div {
    height: 2.9629629629629632vh;
    position: relative;
    top: -0.3703703703703704vh
  }
  
  .mainWrapper section.intro .bottomBlock .desc div:first-child {
    margin-bottom: 2.469135802469136vh
  }
  
  .mainWrapper section.intro .bottomBlock .awards {
    margin-left: 14.930555555555555vw;
    font-family: GothamProBlack;
    font-size: 2.2222222222222223vh;
    line-height: 2.5925925925925926vh;
    vertical-align: top;
    position: relative
  }
  
  .mainWrapper section.intro .bottomBlock .awards div:first-child {
    top: -0.4938271604938272vh
  }
  
  .mainWrapper section.info {
    height: 100%;
    padding-left: 6.25vw;
    font-size: 0;
    position: relative
  }
  
  .mainWrapper section.info .data,
  .mainWrapper section.info .awards {
    display: inline-block;
    vertical-align: top;
    width: 43.75vw;
    padding-right: 6.25vw;
    height: 100%
  }
  
  .mainWrapper section.info .data .topBlock,
  .mainWrapper section.info .awards .topBlock,
  .mainWrapper section.info .data .bottomBlock,
  .mainWrapper section.info .awards .bottomBlock {
    height: 50%
  }
  
  .mainWrapper section.info .data .topBlock,
  .mainWrapper section.info .awards .topBlock {
    padding-top: 29.876543209876544vh
  }
  
  .mainWrapper section.info .data .title,
  .mainWrapper section.info .awards .title {
    font-family: GothamProBold;
    font-size: 4.938271604938272vh;
    letter-spacing: .06944444444444445vw;
    line-height: 3.4567901234567904vh;
    padding: 0;
    margin: 0 0 2.9629629629629632vh;
    margin-left: -0.3703703703703704vh
  }
  
  .mainWrapper section.info .data .title.non,
  .mainWrapper section.info .awards .title.non {
    margin-left: 0
  }
  
  .mainWrapper section.info .data .desc,
  .mainWrapper section.info .awards .desc {
    font-size: 1.3580246913580247vh;
    line-height: .9876543209876544vh
  }
  
  .mainWrapper section.info .data .infoRow,
  .mainWrapper section.info .awards .infoRow {
    margin: 0 0 3.7037037037037037vh
  }
  
  .mainWrapper section.info .data .infoRow .label,
  .mainWrapper section.info .awards .infoRow .label {
    float: left;
    width: 13.88888888888889vw;
    font-size: 1.3580246913580247vh;
    line-height: 1.6049382716049383vh
  }
  
  .mainWrapper section.info .data .infoRow .value,
  .mainWrapper section.info .awards .infoRow .value {
    margin-left: 13.88888888888889vw;
    font-family: GothamProBlack;
    font-size: 2.2222222222222223vh;
    letter-spacing: .06944444444444445vw;
    line-height: 1.6049382716049383vh
  }
  
  .mainWrapper section.info .data {
    background-color: #f9f9f9
  }
  
  .mainWrapper section.info .awards .bottomBlock .infoRow .label {
    width: 10vw
  }
  
  .mainWrapper section.info .awards .bottomBlock .infoRow .value {
    margin-left: 10vw
  }
  
  .mainWrapper section.info .marble {
    position: absolute;
    bottom: 0;
    left: 39.93055555555556vw;
    height: 6.172839506172839vh;
    width: 43.40277777777778vw;
    background: url("/img/marble.jpg");
    background-size: cover;
    background-position: center center
  }
  
  .mainWrapper section.hireUs {
    height: 100%;
    padding-top: 12.345679012345679vh;
    padding-left: 6.25vw;
    font-size: 0
  }
  
  .mainWrapper section.hireUs .textBlock,
  .mainWrapper section.hireUs .formBlock {
    display: inline-block;
    vertical-align: top
  }
  
  .mainWrapper section.hireUs .textBlock {
    height: 54.32098765432099vh;
    padding-top: .8641975308641976vh;
    width: 47.916666666666664vw;
    margin-left: -0.3472222222222222vw
  }
  
  .mainWrapper section.hireUs .textBlock div {
    font-family: GothamProBold;
    font-size: 9.876543209876544vh;
    letter-spacing: .1388888888888889vw;
    line-height: 6.913580246913581vh;
    color: #000;
    margin-bottom: 2.469135802469136vh
  }
  
  .mainWrapper section.hireUs .textBlock div:nth-child(odd) {
    margin-bottom: 2.5925925925925926vh
  }
  
  .mainWrapper section.hireUs .formBlock {
    min-height: 54.32098765432099vh;
    width: 33.68055555555556vw;
    padding-left: 6.25vw;
    position: relative
  }
  
  .mainWrapper section.hireUs .formBlock .text {
    font-size: 1.3580246913580247vh;
    line-height: 1.8518518518518519vh;
    margin: 0 0 7.654320987654321vh;
    width: 19.791666666666668vw;
    height: 4.938271604938272vh
  }
  
  .mainWrapper section.hireUs .formBlock .text div {
    position: relative;
    top: -0.2469135802469136vh
  }
  
  .mainWrapper section.hireUs .formBlock .submitWrapper {
    position: absolute;
    bottom: 0;
    width: 12.5vw;
    right: 0
  }
  
  .mainWrapper section.service {
    padding-top: 12.345679012345679vh;
    padding-left: 20.13888888888889vw;
    padding-top: 33.333333333333336vh;
    font-size: 0
  }
  
  .mainWrapper section.service .title {
    font-family: GothamProBold;
    font-size: 4.938271604938272vh;
    line-height: 4.691358024691358vh;
    height: 3.9506172839506175vh;
    padding-left: 29.86111111111111vw;
    margin: 0 0 17.037037037037038vh
  }
  
  .mainWrapper section.service .title .counter {
    font-size: 1.3580246913580247vh;
    line-height: .9876543209876544vh
  }
  
  .mainWrapper section.service .description,
  .mainWrapper section.service .list {
    display: inline-block;
    vertical-align: top;
    font-size: 1.3580246913580247vh;
    line-height: 1.8518518518518519vh
  }
  
  .mainWrapper section.service .description {
    width: 19.791666666666668vw;
    margin-right: 10.069444444444445vw
  }
  
  .mainWrapper section.service .description div {
    margin: 0 0 3.7037037037037037vh
  }
  
  .mainWrapper section.service .description div.thin {
    font-family: GothamProRegular;
    font-size: 1.234567901234568vh;
    letter-spacing: .20833333333333331vw
  }
  
  .mainWrapper section.service .list {
    width: 18.75vw;
    margin-right: 6.25vw
  }
  
  .mainWrapper section.service .list ul {
    padding: 0;
    margin: 0
  }
  
  .mainWrapper section.service .list ul li {
    margin: 0 0 1.7283950617283952vh;
    padding: 0 0 0 1.3888888888888888vw;
    position: relative;
    list-style: none;
    font-size: 0;
    line-height: 0
  }
  
  .mainWrapper section.service .list ul li span {
    font-size: 1.3580246913580247vh;
    line-height: 1.8518518518518519vh;
    display: inline-block
  }
  
  .mainWrapper section.service .list ul li:before {
    content: '';
    display: block;
    position: absolute;
    height: 4px;
    width: 4px;
    background-color: #000;
    border-radius: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto
  }
  
  .mainWrapper section.service .rect {
    display: inline-block;
    vertical-align: top;
    padding: 2.469135802469136vh 1.3888888888888888vw;
    position: relative;
    background-size: cover;
    background-position: center center
  }
  
  .mainWrapper section.service .rect .resolution {
    font-family: GothamProBold;
    font-size: .9876543209876544vh;
    letter-spacing: .3472222222222222vw;
    line-height: .7407407407407408vh;
    position: relative
  }
  
  .mainWrapper section.service .rect:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
  }
  
  .mainWrapper section.service .rect:hover .resolution,
  .mainWrapper section.service .rect:hover:before {
    opacity: 0 !important;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important
  }
  
  .mainWrapper section.service.web .rect {
    width: 12.5vw;
    height: 12.962962962962964vh;
    background-image: url("/img/gifs/web.gif")
  }
  
  .mainWrapper section.service.mobile .rect {
    width: 4.861111111111111vw;
    height: 16.666666666666668vh;
    background-image: url("/img/gifs/mobile.gif")
  }
  
  .mainWrapper section.service.mobile .rect .resolution {
    writing-mode: vertical-lr;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  
  .mainWrapper section.service.rules .rect {
    width: 12.5vw;
    height: 33.333333333333336vh;
    background-image: url("/img/gifs/rules.gif")
  }
  
  .mainWrapper section.error {
    font-size: 0
  }
  
  .mainWrapper section.error .inner {
    text-align: left;
    padding-top: 33.333333333333336vh;
    padding-left: 20.13888888888889vw
  }
  
  .mainWrapper section.error .inner>div {
    display: inline-block;
    vertical-align: top
  }
  
  .mainWrapper section.error .inner .contentWrapper {
    width: 20.13888888888889vw;
    margin-right: 9.722222222222221vw
  }
  
  .mainWrapper section.error .inner .contentWrapper .message {
    font-size: 1.3580246913580247vh;
    line-height: 1.234567901234568vh;
    margin: 0 0 15.4320987654321vh
  }
  
  .mainWrapper section.error .inner .contentWrapper .btn {
    width: 12.5vw
  }
  
  .mainWrapper section.error .inner .imageWrapper {
    height: 33.333333333333336vh;
    width: 37.5vw;
    background-color: rgba(0, 0, 0, 0.03);
    background-image: url("/img/404.jpg");
    background-size: cover;
    background-position: center center
  }
  
  .mainWrapper section.contact {
    text-align: left;
    padding-top: 33.333333333333336vh;
    font-size: 0;
    position: relative
  }
  
  .mainWrapper section.contact:before {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 100vh;
    left: 50vw;
    top: 0;
    background: rgba(0, 0, 0, 0.1)
  }
  
  .mainWrapper section.contact .infoTextRight {
    position: absolute;
    left: 60.06944444444444vw;
    top: 12.345679012345679vh;
    width: 19.791666666666668vw;
    font-size: 1.3580246913580247vh;
    line-height: 1.8518518518518519vh
  }
  
  .mainWrapper section.contact .infoTextLeft {
    position: fixed;
    left: 6.25vw;
    bottom: 12.345679012345679vh;
    font-size: 10px;
    line-height: 12px;
    max-width: 43.75vw
  }
  
  .mainWrapper section.contact .contactInfo {
    display: inline-block;
    width: 50vw;
    padding-left: 6.25vw
  }
  
  .mainWrapper section.contact .contactInfo .contactRow {
    line-height: 2.7160493827160495vh
  }
  
  .mainWrapper section.contact .contactInfo .contactRow.social {
    padding-top: 3.7037037037037037vh
  }
  
  .mainWrapper section.contact .contactInfo .contactRow.social .value div:last-child {
    margin-bottom: 0
  }
  
  .mainWrapper section.contact .contactInfo .contactRow>div {
    display: inline-block;
    vertical-align: top
  }
  
  .mainWrapper section.contact .contactInfo .contactRow .label {
    font-size: 1.3580246913580247vh;
    width: 13.88888888888889vw
  }
  
  .mainWrapper section.contact .contactInfo .contactRow .value {
    font-family: GothamProBlack;
    font-size: 2.2222222222222223vh;
    letter-spacing: .06944444444444445vw
  }
  
  .mainWrapper section.contact .contactInfo .contactRow .value a {
    display: block
  }
  
  .mainWrapper section.contact .contactInfo .contactRow .value div {
    margin: 0 0 2.5925925925925926vh
  }
  
  .mainWrapper section.contact .formWrapper {
    display: inline-block;
    vertical-align: top;
    width: 27.430555555555554vw;
    margin-left: 10.069444444444445vw
  }
  
  .mainWrapper section.contact .submitWrapper {
    text-align: right;
    margin: 6.049382716049383vh 0 0
  }
  
  .mainWrapper section.contact .submitWrapper .btn {
    width: 12.5vw
  }
  
  .mainWrapper section.works h1 {
    position: absolute
  }
  
  .mainWrapper section.works .hireUsWrapper {
    display: none
  }
  
  .mainWrapper section.works .worksList {
    padding: 33.333333333333336vh 6.25vw 0;
    font-size: 0
  }
  
  .mainWrapper section.works .worksList .work {
    margin-bottom: 4.938271604938272vh
  }
  
  .mainWrapper section.works .worksList .work .imgWrapper,
  .mainWrapper section.works .worksList .work .infoWrapper {
    display: inline-block;
    vertical-align: top
  }
  
  .mainWrapper section.works .worksList .work .imgWrapper {
    width: 43.75vw;
    height: 66.66666666666667vh;
    overflow: hidden
  }
  
  .mainWrapper section.works .worksList .work .imgWrapper .image {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s
  }
  
  .mainWrapper section.works .worksList .work .imgWrapper:hover .image {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05)
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper {
    padding: 9.876543209876544vh 0 0 5.555555555555555vw;
    width: 37.5vw
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .title {
    font-family: GothamProBold;
    font-size: 0;
    margin: 0 0 3.0864197530864197vh
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .title span {
    font-size: 4.938271604938272vh;
    line-height: 4.691358024691358vh;
    display: inline-block
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .image {
    display: none
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .counter {
    font-size: 0;
    margin: 0 0 1.234567901234568vh
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .counter span {
    font-size: 1.234567901234568vh;
    line-height: 1.234567901234568vh;
    display: inline-block
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .desc,
  .mainWrapper section.works .worksList .work .infoWrapper .action {
    font-size: 1.3580246913580247vh;
    line-height: 1.8518518518518519vh;
    display: inline-block
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .action {
    float: right
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .btns {
    margin: 3.7037037037037037vh 0 0
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .btns .btn {
    width: 8.333333333333334vw;
    vertical-align: middle;
    height: 3.4567901234567904vh
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .btns .btn:after {
    line-height: 3.4567901234567904vh
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .btns .btn div {
    line-height: 3.4567901234567904vh;
    font-size: 2.9629629629629632vh
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .btns .outerLink {
    display: inline-block;
    vertical-align: middle;
    margin: 0 1.3888888888888888vw
  }
  
  .mainWrapper section.works .worksList .work .infoWrapper .btns .outerLink img {
    height: 2.7160493827160495vh;
    display: block
  }
  
  .mainWrapper section.works .worksList .work.n2,
  .mainWrapper section.works .worksList .work.n4,
  .mainWrapper section.works .worksList .work.n6 {
    direction: rtl
  }
  
  .mainWrapper section.works .worksList .work.n2>div,
  .mainWrapper section.works .worksList .work.n4>div,
  .mainWrapper section.works .worksList .work.n6>div {
    direction: ltr
  }
  
  .mainWrapper section.works .worksList .work.n2 .imgWrapper,
  .mainWrapper section.works .worksList .work.n4 .imgWrapper,
  .mainWrapper section.works .worksList .work.n6 .imgWrapper {
    width: 37.5vw
  }
  
  .mainWrapper section.works .worksList .work.n2 .infoWrapper,
  .mainWrapper section.works .worksList .work.n4 .infoWrapper,
  .mainWrapper section.works .worksList .work.n6 .infoWrapper {
    padding: 9.876543209876544vh 5.555555555555555vw 0 0;
    width: 43.75vw;
    text-align: right
  }
  
  .mainWrapper section.works .worksList .work.n2 .infoWrapper .action,
  .mainWrapper section.works .worksList .work.n4 .infoWrapper .action,
  .mainWrapper section.works .worksList .work.n6 .infoWrapper .action {
    float: left
  }
  
  .mainWrapper section.works .worksList .work.n2 .infoWrapper .btns,
  .mainWrapper section.works .worksList .work.n4 .infoWrapper .btns,
  .mainWrapper section.works .worksList .work.n6 .infoWrapper .btns {
    direction: rtl
  }
  
  .mainWrapper section.works .worksList .work.n2 .infoWrapper .btns .btn,
  .mainWrapper section.works .worksList .work.n4 .infoWrapper .btns .btn,
  .mainWrapper section.works .worksList .work.n6 .infoWrapper .btns .btn {
    direction: ltr
  }
  
  .mainWrapper section.works .worksList .work.n2 {
    margin-bottom: 24.691358024691358vh
  }
  
  .mainWrapper section.works .worksList .work.n2 .imgWrapper {
    margin-top: -17.283950617283953vh;
    height: 75.30864197530865vh
  }
  
  .mainWrapper section.works .worksList .work.n2:first-child .imgWrapper {
    margin-top: 0
  }
  
  .mainWrapper section.works .worksList .work.n3 {
    margin-bottom: 4.938271604938272vh
  }
  
  .mainWrapper section.works .worksList .work.n3 .imgWrapper {
    height: 54.32098765432099vh
  }
  
  .mainWrapper section.works .worksList .work.n4 {
    margin-bottom: 20.987654320987655vh
  }
  
  .mainWrapper section.works .worksList .work.n4 .imgWrapper {
    margin-top: -17.283950617283953vh;
    height: 66.66666666666667vh
  }
  
  .mainWrapper section.works .worksList .work.n4:first-child .imgWrapper {
    margin-top: 0
  }
  
  .mainWrapper section.works .worksList .work.n5 {
    margin-bottom: 24.691358024691358vh
  }
  
  .mainWrapper section.works .worksList .work.n5 .imgWrapper {
    height: 66.66666666666667vh
  }
  
  .mainWrapper section.works .worksList .work.n6 {
    margin-bottom: 24.691358024691358vh
  }
  
  .mainWrapper section.works .worksList .work.n6 .imgWrapper {
    height: 50vh
  }
  
  .mainWrapper section.works .worksText {
    padding-left: 6.25vw;
    font-family: GothamProBold;
    font-size: 4.938271604938272vh;
    line-height: 3.4567901234567904vh;
    margin: 0 0 11.604938271604938vh
  }
  
  .mainWrapper section.works .worksText>div:first-child {
    margin-bottom: 1.4814814814814816vh
  }
  
  .mainWrapper section.works .worksText .dribbble:hover {
    color: #ea4c89
  }
  
  .mainWrapper section.works .worksText .behance:hover {
    color: #1769ff
  }
  
  .pager .bullets .bullet {
    display: inline-block;
    height: 11px;
    width: 11px;
    position: relative
  }

  .pager.bottom .bullets .bullet:nth-child(2) {
    left: calc(4.134722vw - 5px);
  }

  .pager.bottom .bullets .bullet:last-child {
    left: calc(8.369444vw - 11px);
  }
  
  .pager .bullets .bullet:before {
    content: '';
    display: block;
    position: absolute;
    /* left: 0; */
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #000;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important
  }
  
  .pager .labelWrapper .label {
    display: inline-block;
    font-size: 10px;
    line-height: 11px;
    color: #fff;
    background: #000;
    padding: 0 3px
  }
  
  .pager.bottom {
    position: fixed;
    bottom: 12.345679012345679vh;
    left: 39.93055555555556vw;
    width: 20.13888888888889vw;
    font-size: 0
  }
  
  .pager.bottom>div {
    display: inline-block;
    vertical-align: middle;
    width: 50%
  }
  
  .pager.bottom .bullets {
    text-align: justify
  }
  
  .pager.bottom .bullets:after {
    content: '';
    display: inline-block;
    width: 100%
  }
  
  .pager.bottom .bullets .bullet.active:before {
    width: 0 !important
  }
  
  .pager.bottom .bullets .bullet.active:nth-child(3):before {
    width: 11px !important
  }
  
  .pager.bottom .bullets.s1.s0 .bullet.active:nth-child(2):before {
    width: 11px !important
  }
  
  .pager.left {
    position: fixed;
    top: 33.333333333333336vh;
    left: 6.25vw;
    font-size: 0
  }
  
  .pager.left>div {
    display: inline-block;
    vertical-align: middle;
    width: 50%
  }
  
  .pager.left .bullets .bullet {
    margin-bottom: 14.567901234567902vh
  }
  
  .pager.left .bullets .bullet:nth-child(3):before {
    margin: 0;
  }


  
  .pager.left .bullets .bullet.active:before {
    height: 0 !important
  }
  
  .pager.left .bullets .bullet.active:nth-child(3):before {
    height: 11px !important
  }
  
  .pager.left .bullets.s1.s0 .bullet.active:nth-child(2):before {
    height: 11px !important
  }
  
  .pager.left .labelWrapper {
    position: absolute;
    bottom: 0;
    left: 13.88888888888889vw;
    white-space: nowrap
  }

  .pager.left .labelWrapper .label {
    bottom: 15vh;
  }
  
  .fullscreenTriggerClose .sidebar .fullscreenTrigger .close {
    display: inline-block
  }
  
  .fullscreenTriggerClose .sidebar .fullscreenTrigger .open {
    display: none
  }
  
  :-moz-full-screen .sidebar .fullscreenTrigger .close {
    display: inline-block
  }
  
  :-moz-full-screen .sidebar .fullscreenTrigger .open {
    display: none
  }
  
  :-webkit-full-screen .sidebar .fullscreenTrigger .close {
    display: inline-block
  }
  
  :-webkit-full-screen .sidebar .fullscreenTrigger .open {
    display: none
  }
  
  :fullscreen .sidebar .fullscreenTrigger .close {
    display: inline-block
  }
  
  :fullscreen .sidebar .fullscreenTrigger .open {
    display: none
  }
  
  .thanqWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    z-index: 20;
    text-align: center;
    background: #fff;
    display: none
  }
  
  .thanqWrapper .outer {
    display: table;
    width: 100%;
    height: 100%
  }
  
  .thanqWrapper .outer>.inner {
    vertical-align: middle;
    display: table-cell
  }
  
  .thanqWrapper img {
    display: block;
    margin: auto;
    width: 10.416666666666666vw
  }
  
  .thanqWrapper .title {
    font-family: GothamProBlack;
    font-size: 2.2222222222222223vh;
    line-height: 1.6049382716049383vh;
    margin: 3.7037037037037037vh auto 1.8518518518518519vh
  }
  
  .thanqWrapper .desc {
    font-size: 1.3580246913580247vh;
    line-height: 1.8518518518518519vh;
    margin: 0 auto 4.938271604938272vh
  }
  
  .thanqWrapper .counter {
    font-size: 1.3580246913580247vh;
    line-height: .9876543209876544vh;
    margin: 2.469135802469136vh auto 6.172839506172839vh
  }
  
  .thanqWrapper.loading .line .inner {
    width: 100%
  }
  
  .thanqWrapper .line {
    background: #ebebeb;
    width: 13.680555555555555vw;
    margin: auto
  }
  
  .thanqWrapper .line .inner {
    width: 0;
    height: 1px;
    background: #000;
    -webkit-transition: all 3.5s linear;
    -moz-transition: all 3.5s linear;
    -ms-transition: all 3.5s linear;
    -o-transition: all 3.5s linear;
    transition: all 3.5s linear
  }
  
  .servicesTitles {
    position: fixed;
    top: 12.345679012345679vh;
    left: 50vw;
    height: 4.197530864197531vh;
    width: 25vw
  }
  
  .servicesTitles a {
    font-family: GothamProBold;
    font-size: 4.938271604938272vh;
    line-height: 4.691358024691358vh;
    height: 4.197530864197531vh;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    white-space: nowrap
  }
  
  .servicesTitles a .counter {
    font-size: 1.3580246913580247vh;
    line-height: .9876543209876544vh
  }
  
  .preloader {
    position: fixed;
    z-index: 30;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff
  }
  
  .preloader img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 11.11111111111111vw;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }
  
  .cookiePolicyWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    padding: 20px;
    z-index: 12;
    display: none
  }
  
  .cookiePolicyWrapper .closeBtn {
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    -moz-transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    transition: all .5s cubic-bezier(0.6, 0, 0.5, 1);
    font-size: 12px;
    color: #fff;
    font-family: GothamProMedium;
    letter-spacing: .43px;
    line-height: 35px;
    position: absolute;
    right: 20px;
    padding: 0 20px;
    height: 37px;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center
  }
  
  .cookiePolicyWrapper .closeBtn:hover {
    border-color: #fff
  }
  
  .cookiePolicyWrapper .text {
    font-family: GothamProRegular;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: .25px;
    color: #fff
  }
  
  .cookiePolicyWrapper .text a {
    font-family: GothamProMedium;
    color: #fff;
    text-decoration: underline
  }
  
  .startPreload .preloader img,
  .startPreload .preloader svg {
    opacity: 1
  }
  
  .preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    z-index: 20
  }
  
  .preloader img,
  .preloader svg {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 160px;
    height: 64px
  }
  
  .preloader .st1 {
    fill: #fff
  }
  
  .preloader .cls-2 {
    fill: none;
    stroke: #121212;
    stroke-width: 10;
    stroke-miterlimit: 10
  }

  #thankyou {
    display: none;
  }

  /* AOS */
  .revealLTR,
.revealRTL,
.revealTTB,
.revealBTT {
	position: relative;
	overflow: hidden;
}
.revealLTR[data-aos=fade].aos-animate > *,
.revealRTL[data-aos=fade].aos-animate > *,
.revealTTB[data-aos=fade].aos-animate > *,
.revealBTT[data-aos=fade].aos-animate > * {
	animation: fadeIn 0s 0.6s;
	animation-fill-mode: backwards;
}
.revealLTR[data-aos="fade"]:not(.aos-animate),
.revealRTL[data-aos="fade"]:not(.aos-animate),
.revealTTB[data-aos="fade"]:not(.aos-animate),
.revealBTT[data-aos="fade"]:not(.aos-animate) {
    opacity: 0!important;
}
.revealLTR[data-aos=fade].aos-animate::after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: white;
	transform: translateX(-100%);
	animation: revealInLTR 0.6s 0s, revealOutLTR 0.6s 0.6s;
	animation-fill-mode: forwards;
}
.revealRTL[data-aos=fade].aos-animate::after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: white;
	transform: translateX(100%);
	animation: revealInRTL 0.6s 0s, revealOutRTL 0.6s 0.6s;
	animation-fill-mode: forwards;
}
.revealTTB[data-aos=fade].aos-animate::after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: white;
	transform: translateY(-100%);
	animation: revealInTTB 0.6s 0s, revealOutTTB 0.6s 0.6s;
	animation-fill-mode: forwards;
}
.revealBTT[data-aos=fade].aos-animate::after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: white;
	transform: translateY(100%);
	animation: revealInBTT 0.6s 0s, revealOutBTT 0.6s 0.6s;
	animation-fill-mode: forwards;
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes revealInLTR {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}
@keyframes revealOutLTR {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(101%);
	}
}
@keyframes revealInRTL {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}
@keyframes revealOutRTL {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-101%);
	}
}
@keyframes revealInTTB {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes revealOutTTB {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(101%);
	}
}
@keyframes revealInBTT {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes revealOutBTT {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-101%);
	}
}